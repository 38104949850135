<template>
  <div class='notfound'>
    <van-nav-bar @click-left='$router.push("/")' style='padding-top: 10px;'>
      <template #left>
        <img style='width: 40px;height: auto;' :src='$baseInfo.LOGO_IMG' alt=''>
        <span style='padding-left: 7px;font-size: 13px;'>凯迪网首页</span>
      </template>
      <template #right>
      </template>
    </van-nav-bar>
    <div class='img'></div>
  </div>
</template>

<script>
export default {
  name: 'Notfound'
}
</script>

<style scoped lang='less'>
.notfound {
  .img {
    width: 257px;
    height: 257px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: url("https://cdn.9kd.com/kdnet/wap404.png") no-repeat center center;
    background-size: 100% 100%;
  }
}
</style>
